import App from 'next/app';
import { AppProvider } from '@shopify/polaris';
import { Provider } from '@shopify/app-bridge-react';
import translations from '@shopify/polaris/locales/en.json';
import { SessionProvider } from 'next-auth/react';
// import "@shopify/polaris/build/esm/styles.css"; // Polaris Import is conditional for Admin Pages now
import './styles.scss'; //Bootstrap Import

import { PUBLIC_PATHS, ACCOUNT_AND_TRACKING_PATHS, ADMIN_PATHS } from '../utilities/constants';
import { Layout } from '../components/account/Layout';

class MyApp extends App {
    render() {
        const { Component, pageProps, router, host } = this.props;
        const { pathname, query } = router;

        if (ADMIN_PATHS.find((admin_path) => pathname.startsWith(admin_path))) {
            return (
                <AppProvider i18n={translations}>
                    <link
                        rel="stylesheet"
                        href="https://unpkg.com/@shopify/polaris@10.43.0/build/esm/styles.css"
                    />
                    <Provider
                        config={{
                            apiKey: API_KEY,
                            host: host,
                            forceRedirect: true
                        }}
                    >
                        <Component {...pageProps} />
                    </Provider>
                </AppProvider>
            );
        }

        if (ACCOUNT_AND_TRACKING_PATHS.find((account_path) => pathname.startsWith(account_path))) {
            const getLayout = Component.getLayout || ((page) => page);

            return (
                <SessionProvider basePath="/api/account/auth" session={pageProps.session}>
                    <Layout>{getLayout(<Component {...pageProps} />)}</Layout>
                </SessionProvider>
            );
        }

        //We have a custom layout for the seller page(or others)
        //We don't want the appbrige and the other crap
        const customLayout = Component.getLayout;
        console.log('NonPolaris Public Site');

        if (customLayout && typeof customLayout === 'function') {
            return customLayout(
                <>
                    <Component {...pageProps} pathName={pathname} queryParams={query} />
                </>
            );
        }

        return (
            <>
                <Component {...pageProps} pathName={pathname} queryParams={query} />
            </>
        );
    }
}

MyApp.getInitialProps = async ({ ctx }) => {
    //This App initialization is common to the entire app.

    // We want a different layout and provider for the public facing selling app vs the others
    console.log('Request At', ctx.pathname);
    if (PUBLIC_PATHS.find((public_path) => ctx.pathname.startsWith(public_path))) {
        return {
            query: ctx.query
        };
    }

    return {
        host: ctx.query.host
    };
};

export default MyApp;
