import styles from './Layout.module.css';
import { TopNav } from './TopNav';

export function Layout({ children }) {
    return (
        <div className={styles.layout_wrapper}>
            <TopNav />
            {children}
        </div>
    );
}
