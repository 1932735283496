// import { findPackageData } from "@babel/core/lib/config/files";
// import { ComputeOptimizer } from "aws-sdk";

export const ACCOUNT_AND_TRACKING_PATHS = [
    '/account',
    '/api/account',
    '/order-status/track',
    '/order-status/find-your-order'
];

export const ADMIN_PATHS = ['/admin', '/api/admin'];

export const PUBLIC_PATHS = ['/sell', '/shop', '/api/sell/'];

export const APP_SCOPE = [
    'write_products',
    'read_products',
    'read_orders',
    'write_orders',
    'read_all_orders',
    'read_themes',
    'write_themes',
    'read_customers',
    'read_script_tags',
    'write_script_tags',
    'read_shipping',
    'write_shipping',
    'read_fulfillments', // Your 3rd Party Fulfillment Service
    'write_fulfillments',
    'read_merchant_managed_fulfillment_orders', // Merchant Fulfillment Service
    'write_merchant_managed_fulfillment_orders',
    'read_discounts',
    'write_discounts'
    // "read_third_party_fulfillment_orders",
    // "write_third_party_fulfillment_orders", // Any 3rd Party Fulfillment Service
];

export const MIN_DISCOUNT = 25; //This needs to be fetched from settings
export const MAX_AGE_DISCOUNT = 10;
export const MONTH_DISCOUNT_MODIFIER = MAX_AGE_DISCOUNT / 36;
export const TAGS_DISCOUNT_MODIFIER = 15;
export const MAX_CONDITION_DISCOUNT = 35;

//Razorpay Webhook Events
//Virtual Account (VA)
export const RAZORPAY_EVENT_VA_CREDITED = 'virtual_account.credited';
export const RAZORPAY_EVENT_VA_CLOSED = 'virtual_account.closed';

//Payment Links (PL)
export const RAZORPAY_EVENT_PL_PAID = 'virtual_account.paid';

export const VA_CREDITED_TYPE_BANK_TRANSFER = 'bank_transfer';
export const VA_CREDITED_TYPE_UPI_TRANSFER = 'upi_transfer';

export const ENTITY_SHOP = 'shop';
export const ENTITY_CUSTOMER = 'customer';

export const WEBHOOKS_TOPIC_DATA_REQUEST = 'data_request';
export const WEBHOOKS_TOPIC_REDACT = 'redact';

export const DEFAULT_PRODUCT_TYPE_ID = 'default_type';

export const MARKETING_TYPE_ABANDONED_CART_ID = 'abandonedCartEmailer';
export const MARKETING_TYPE_POST_ORDER_ID = 'postOrderEmailer';

export const DISCOUNT_TYPE = {
    amount: 'amount',
    percent: 'percent'
};

export const OPTION_NO_SIZE = 'No Size';
export const OPTION_ANY_SIZE = 'All Sizes';

export const SHOP_NAME_MAP = {
    'a-pirani': 'anushé pirani',
    'aaheli-our-honest-attempt-to-create-everyday-apparels': 'Aaheli ',
    aastey: 'aastey',
    ambraeebypalak: 'Ambraee',
    amydus2: 'Amydus',
    'bee-clothing-aksama': 'BEE Clothing',
    'blabel-in': 'B Label',
    'bunaai-com': 'Bunaai',
    'cement-feet': 'Circle',
    chanderimechowdhrain: 'Chowdhrain',
    'chumbak-sale': 'Chumbak Sale',
    chumbakdesign: 'Chumbak',
    dressfolk: 'Dressfolk',
    fabnestonline: 'Fabnest',
    'fancy-pastels': 'Fancy Pastels',
    getcircle: 'Relove',
    'gulaal-creations': 'Gulaal',
    hastii: 'Flourish',
    'hello-5a87': 'LoveChange™',
    'house-of-moxa': 'House of Moxa',
    housethis: 'Housethis',
    'huts-and-looms': 'Huts and Looms',
    'infowind-rajan': 'Infowind Rajan',
    'insoulslings-com': 'Soul Baby Carriers',
    'its-summer-somewhere': 'Summer Somewhere',
    'ka-sha-ka-sha': 'Ka-Sha',
    'kanelle-online': 'Kanelle',
    'kavana-in': 'kavana.in',
    'kelbyhuston-in': 'Kelby Huston',
    kharakapas: 'Khara Kapas',
    'label-raasleela-shop': 'Label Raasleela',
    'linentrail-com': 'Linen Trail',
    'love-the-world-today': 'Love the world today',
    maadees: 'Moonstruck',
    'miko-lolo': 'Miko Lolo',
    'nete-shop': 'NETE.IN ',
    nonasties: 'No Nasties',
    okhaistore: 'Okhai',
    pachoulijaipur: 'Pachouli Jaipur',
    'panchhi-pret-couture': 'Panchhi',
    'pause-womenswear': 'Pause',
    'pheeta-store': 'pheeta',
    'pranit-dev-store': 'pranit_dev_store',
    'prathaa-weaving-traditions': 'Prathaa',
    'raisinglobal-com': 'Raisin ',
    'relove-development': 'The Relove Development Store',
    ruworldstore: 'RU.World',
    'saadaa-design': ' सादा / SAADAA',
    'saaki-merch': 'Saaki',
    'sajke-in': 'SajKe.in',
    'saundh-india': 'Saundh India',
    'shauraya-sanadhya': 'Label Shaurya Sanadhya',
    shopdrawn: 'Drawn',
    'shruti-sancheti': 'Shruti Sancheti',
    'snitch-4m-clothing-llp': 'SNITCH',
    'suta-in': 'suta',
    'tachyon-prod-2': 'tachyon-prod-2',
    'the-indian-ethnic-company': 'THE INDIAN ETHNIC CO.',
    'the-jodi-life': 'JODI',
    thelabellifeethnic: 'ABHISHTI',
    theputchi: 'Putchi',
    thesummerhouse: 'The Summer House',
    threadnbutton: 'Thread & Button',
    tjoritreasures: 'TJORI',
    'tokree-shop-jaipur': 'Tokree Shop Jaipur',
    truebrowns: 'trueBrowns',
    'tuna-london': 'Tuna Active',
    'where-stories-come-alive': 'Tura Turi',
    whysoblue: 'WhySoBlue',
    'www-aachho-com': 'Aachho',
    'www.thereyougo.in': 'There You Go',
    zwaan: 'Zwaan'
};
