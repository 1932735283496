import React from 'react';
import { useRouter } from 'next/router';
import styles from './TopNav.module.css';
import Meta from 'components/public/Meta';
import { signOut, useSession } from 'next-auth/react';
import { Dropdown, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { SHOP_NAME_MAP } from 'utilities/constants';

const LOGIN_PATH = '/account/auth/login';

export function TopNav() {
    const router = useRouter();
    const session = useSession();

    let { shop } = router.query;
    const pathname = router.pathname;
    const shopName = SHOP_NAME_MAP[shop];

    return (
        <>
            {pathname === LOGIN_PATH ? (
                <>
                    <Meta title="Relove - Login" addClarity={true} />
                </>
            ) : (
                <div className={styles.account_navbar}>
                    <Navbar expand="xl">
                        <Navbar.Brand href="/account/listings">
                            <div className={styles.top_nav_logo_brand}>
                                <div>
                                    <img
                                        className={styles.top_nav_relove_logo}
                                        src="/relove-black-offset.png"
                                    />
                                </div>
                                {shop !== undefined && (
                                    <div className={styles.top_nav_logo_brand_text}>
                                        {shopName || shop}
                                    </div>
                                )}
                            </div>
                        </Navbar.Brand>
                        <Navbar.Toggle
                            bsPrefix="custom-toggle" // just to get rid of the base styling
                            className={styles.custom_toggle_button}
                            aria-controls="offcanvasNavbar-expand-xl"
                            style={{ border: 'none', background: 'none' }}
                        >
                            <HamIcon />
                        </Navbar.Toggle>
                        <Navbar.Offcanvas
                            placement="end"
                            id={`offcanvasNavbar-expand-xl`}
                            className={styles.bootstrap_offcanvas}
                            aria-labelledby="offcanvasNavbarLabel-expand-xl"
                        >
                            <Offcanvas.Header
                                style={{ justifyContent: 'flex-end', textAlign: 'right' }}
                            >
                                <p className={styles.drawer_menu_text}>Menu</p>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav
                                    className={
                                        styles.top_nav_shop_name_wrapper +
                                        ' justify-content-end flex-grow-1 pe-3'
                                    }
                                >
                                    {shop !== undefined && (
                                        <Nav.Link
                                            target="_blank"
                                            className={styles.top_nav_shop_link}
                                            href={`https://${shop}.myshopify.com`}
                                        >
                                            <div>Back to {shopName || shop}</div>
                                            <div>
                                                <img
                                                    width="22"
                                                    height="22"
                                                    src={
                                                        process.env
                                                            .NEXT_PUBLIC_S3_IMAGE_BUCKET_CDN +
                                                        'relove-wardrobe/new_tab.svg'
                                                    }
                                                />
                                            </div>
                                        </Nav.Link>
                                    )}
                                    <Nav.Link
                                        target="_blank"
                                        href="https://www.relove.in/shop"
                                        className={styles.top_nav_shop_link}
                                    >
                                        Shop Relove
                                    </Nav.Link>
                                    <Navbar.Text>
                                        {session?.status === 'unauthenticated' ? (
                                            <Nav.Item
                                                onClick={() => router.push('/account/auth/login')}
                                                className={
                                                    styles.top_nav_shop_link +
                                                    ' ' +
                                                    styles.log_out_nav_menu
                                                }
                                            >
                                                Log in
                                            </Nav.Item>
                                        ) : (
                                            <Nav.Item
                                                onClick={signOut}
                                                className={
                                                    styles.top_nav_shop_link +
                                                    ' ' +
                                                    styles.log_out_nav_menu
                                                }
                                            >
                                                Log out
                                            </Nav.Item>
                                        )}
                                        <p
                                            className={
                                                styles.nav_user_email_text +
                                                ' ' +
                                                styles.email_text_mobile
                                            }
                                        >
                                            {session?.data?.user?.email}
                                        </p>
                                        <Dropdown className={styles.account_icon}>
                                            <Dropdown.Toggle
                                                as={CustomUserMenuToggle}
                                            ></Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item disabled>
                                                    {session?.data?.user?.email}
                                                </Dropdown.Item>
                                                {session?.status === 'unauthenticated' ? (
                                                    <Dropdown.Item href="/account/auth/login">
                                                        Log in
                                                    </Dropdown.Item>
                                                ) : (
                                                    <Dropdown.Item href="#" onClick={signOut}>
                                                        Log out
                                                    </Dropdown.Item>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Navbar.Text>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Navbar>
                </div>
            )}
        </>
    );
}

const HamIcon = () => {
    return (
        <div className={styles.hamburger_menu_button}>
            <div className={styles.hamburger_menu_line} />
            <div className={styles.hamburger_menu_line} />
            <div className={styles.hamburger_menu_line} />
        </div>
    );
};

const CustomUserMenuToggle = React.forwardRef(({ onClick }, ref) => {
    const session = useSession();
    const user = session?.data?.user;

    return (
        <div
            style={{
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'flex-end',
                flexDirection: 'column'
            }}
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {user?.image ? (
                <>
                    <img width="35" height="35" src={user.image} className={styles.user_image} />
                </>
            ) : (
                <img
                    width="22"
                    height="21"
                    src={
                        process.env.NEXT_PUBLIC_S3_IMAGE_BUCKET_CDN +
                        'relove-wardrobe/user_dummy.svg'
                    }
                />
            )}
        </div>
    );
});
