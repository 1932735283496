import Head from 'next/head';
import Script from 'next/script';

const Meta = ({ title, keywords, description, addClarity }) => {
    return (
        <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="keywords" content={keywords} />
            <meta name="description" content={description} />
            <meta name="theme-color" content="#4e7f5e" />
            <meta
                property="og:image"
                content="https://www.relove.in/img/banner-desktop_c6bd531e-cf8a-49d0-a485-e61683ebf3cd.webp"
            />
            <meta charSet="utf-8" />
            {/* Global site tag (gtag.js) - Google Analytics */}
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-8T67XMVVMW" />
            <script>{injectGA()}</script>
            <link rel="icon" href="/favicon.ico" />
            <title>{title}</title>
            {/* {addClarity && <script type="text/javascript">{injectClarity()}</script>} */}
        </Head>
    );
};

Meta.defaultProps = {
    title: 'Relove',
    keywords: 'shopify, ecommerce, ciruclar economy, resale, thrift',
    description: 'Resale Technology to make your brand circular'
};

const injectClarity = () => {
    if (typeof window == 'undefined') {
        return;
    }
    (function (c, l, a, r, i, t, y) {
        c[a] =
            c[a] ||
            function () {
                (c[a].q = c[a].q || []).push(arguments);
            };
        t = l.createElement(r);
        t.async = 1;
        t.src = 'https://www.clarity.ms/tag/' + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', 'exp0tbjyp4');
};

const injectGA = () => {
    if (typeof window == 'undefined') {
        return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }
    window.gtag = gtag;

    gtag('js', new Date());
    gtag('config', 'G-8T67XMVVMW');
};

export default Meta;
